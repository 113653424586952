import React from 'react'
import { clients } from '../constants'
import styles from '../styles'

function Clients() {
  return (
    <section className={`${styles.flexCenter} my-4`}>
      <div className={`${styles.flexCenter} flex-wrap w-full gap-10`}>
        {clients.map((client) => (
          <div key={client.id} className={`flex-1 ${styles.flexCenter} sm:min-w-[192px] min-w-[120px] client-item`}>
              <img src={client.logo} alt={client.client}  className='sm:w-[192px] w-[100px] object-contain'/>
          </div>
        ))}
      </div>
    </section>
  )
}

export default Clients